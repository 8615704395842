<template>
	<div>
		<UploadPreviewFile
			:uploadFilePath.sync="dtbFile"
			:folderPath="'accounting/dtb'"
			:format="'.excel'"
			:callbackFun="getFilePath"
			:isPreviewFile="false"
		>
			<template v-slot:btn>
				<span class="example-click" style="color: #ccc"> Please upload Dtb statement from Dtb's back end. </span>
				<a-button type="link" @click="showUploadHistory">Upload History</a-button>
			</template>
		</UploadPreviewFile>
		<p v-if="dtbFilePageList && uploadSuccess" style="padding: 10px 0">
			<a :href="$Util.getObsImageUrl($store.state.pageState.authObj, dtbFilePageList[0].filePath)" class="file_name" target="_blank">
				{{ !$Util.isEmpty(dtbFilePageList[0].filePath) ? dtbFilePageList[0].filePath.split('/').at(-1) : '' }}
			</a>
			<span>({{ 'from ' + dtbFilePageList[0].startTime + ' to ' + dtbFilePageList[0].endTime }})</span>
		</p>
		<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
			<template slot="action" slot-scope="{ columnData }">
				<span>
					<a-button :disabled="columnData.processStatus === 1" type="link" @click="uploadRepayment(columnData)">Upload</a-button>
					<a-divider type="vertical" />
					<a-button :disabled="columnData.processStatus === 1" type="link" @click="showIgnore(columnData.id)">Ignore</a-button>
				</span>
			</template>
		</search-list-table>
		<a-modal v-drag-modal v-model="dtbFileListShow" :footer="false" width="1000px" title="Upload History">
			<a-table
				v-if="dtbFilePageList"
				style="font-size: 12px"
				:columns="dtbFileListColumns"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="dtbFilePageList"
				:pagination="pagination"
				bordered
			></a-table>
		</a-modal>
		<upload-repayment ref="uploadRepayment" @repaymentsInitTable="repaymentsInitTable"></upload-repayment>
	</div>
</template>
<script>
import UploadRepayment from '@/views/businessComponents/UploadRepayment.vue'
import moment from 'moment'
import { apiDtbPage, apiDtbFilePage, apiDtbIgnore, apiDtbUploadFile } from '@/api/accountChecking'
export default {
	data() {
		return {
			dtbFilePageList: null,
			dtbFileListShow: false,
			dtbFile: null,
			repayModalShow: false,
			uploadSuccess: false,
			pagination: {
				total: 0,
				showTotal: (total) => `Total ${total} items`,
				hideOnSinglePage: false,
				current: 1,
				showSizeChanger: true,
				pageSize: 10,
				pageSizeOptions: ['10', '20', '50', '100']
			}
		}
	},
	components: { UploadRepayment },
	computed: {
		formSearchList() {
			return [
				// {
				// 	label: 'table.applyInfo.cloums.caseId',
				// 	type: 'input',
				// 	validateField: ['caseId']
				// },
				{
					label: 'table.loanInfo.caseInfo.status',
					type: 'select',
					selectOption: [0, 1],
					validateField: ['processStatus'],
					prefix: 'table.finance.cloum.processStatus'
				},
				{
					label: 'table.finance.cloum.payID',
					type: 'input',
					validateField: ['reference']
				},
				{
					label: 'table.finance.cloum.chequeNo',
					type: 'input',
					validateField: ['chequeNo']
				}
			]
		},
		columns() {
			return [
				{
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					align: 'center',
					width: 80,
					fixed: 'left'
				},
				{
					title: this.$t('table.finance.cloum.receiptNo'),
					dataIndex: 'id',
					align: 'center',
					width: 150,
					fixed: 'left'
				},
				{
					title: this.$t('table.finance.cloum.transactionDate'),
					align: 'center',
					dataIndex: 'transactionDate'
				},
				{
					title: this.$t('table.finance.cloum.valueDate'),
					align: 'center',
					dataIndex: 'valueDate'
				},
				{
					title: this.$t('table.finance.cloum.transactionDetails'),
					dataIndex: 'transactionDetails',
					align: 'center'
				},
				{
					title: this.$t('table.finance.cloum.transactionType'),
					align: 'center',
					dataIndex: 'transactionType'
				},
				{
					title: this.$t('table.finance.cloum.chequeNo'),
					align: 'center',
					dataIndex: 'chequeNumber'
				},
				{
					title: this.$t('table.finance.cloum.debits'),
					align: 'center',
					dataIndex: 'debits',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					title: this.$t('table.finance.cloum.credits'),
					align: 'center',
					dataIndex: 'credits',
					scopedSlots: { customRender: 'money_format' }
				},
				{
					title: this.$t('table.loanInfo.caseInfo.status'),
					align: 'center',
					dataIndex: 'processStatus',
					customRender: (v, o) => {
						return this.$t(`table.finance.cloum.processStatus.${v}`)
					}
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 290,
					fixed: 'right'
				}
			]
		},
		dtbFileListColumns() {
			return [
				{
					title: 'File',
					dataIndex: 'filePath',
					align: 'center'
				},
				{
					title: 'Upload Time',
					dataIndex: 'uploadTime',
					align: 'center'
				},
				{
					title: 'Time Range',
					align: 'center',
					customRender: (obj) => {
						return 'from ' + obj.startTime + ' to ' + obj.endTime
					}
				},
				{
					title: 'Operator',
					align: 'center',
					dataIndex: 'username'
				}
			]
		}
	},
	methods: {
		moment,
		getFilePath(type) {
			this.uploadSuccess = false
			apiDtbUploadFile({ filePath: type }).then((res) => {
				this.getApiDtbFilePage()
				this.uploadSuccess = true
				this.$message.success('Success')
			})
		},
		getApiDtbFilePage() {
			apiDtbFilePage().then((res) => {
				this.dtbFilePageList = res.content
				this.pagination.current = res.number + 1
				this.pagination.pageSize = res.size
				this.pagination.total = res.totalElements
			})
		},
		showUploadHistory() {
			this.getApiDtbFilePage()
			this.dtbFileListShow = true
		},
		uploadRepayment(columnData) {
			this.$refs.uploadRepayment.uploadRepayment(
				null,
				{
					paymentId: columnData.id,
					transactionDate: columnData.transactionDate,
					amount: columnData.credits,
					transactionTypeEnum: columnData.transactionType.toLowerCase().indexOf('cheque') !== -1 && columnData.chequeNumber ? 0 : 1
				},
				'bankAccountChecking'
			)
		},
		initDataSource(initParams) {
			return apiDtbPage(initParams)
		},
		repaymentsInitTable(pageNum) {
			this.$refs.searchListTable.initTable(pageNum)
		},
		showIgnore(id) {
			this.$confirm({
				title: '',
				content: 'Are you sure not to process it?',
				onOk: () => {
					apiDtbIgnore(id)
						.then(() => {
							this.$message.success('Success')
							this.$refs.searchListTable.initTable(1)
						})
						.catch((err) => {})
				},
				onCancel() {}
			})
		}
	}
}
</script>
<style lang="less">
.dtb-upload {
	.upload {
		display: inline-block;
	}
	.tips {
		margin-left: 20px;
		opacity: 0.6;
	}
}

.send {
	display: flex;
	margin-top: 20px;
	.ant-form-item-label {
		font-weight: 600;
	}
	.ant-input,
	.ant-select {
		width: 360px;
		height: 40px;
		line-height: 40px;
	}
}
</style>
